import styled from "@emotion/styled";
import React from "react";

import star1 from "svg/star1.svg";
import star2 from "svg/star2.svg";
import star3 from "svg/star3.svg";
import star4 from "svg/star4.svg";

const Stars = () => {
  return (
    <>
      <PositionedStar
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        data-aos="zoom-in"
        position={{ left: "-140px", bottom: "100px" }}
        src={star1}
        alt="star"
      />
      <PositionedStar
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        data-aos="zoom-in"
        position={{ left: "-23px", top: "-187px" }}
        src={star4}
        alt="star"
      />
      <PositionedStar
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        data-aos="zoom-in"
        position={{ left: "-88px", bottom: "252px" }}
        src={star2}
        alt="star"
      />
      <PositionedStar
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        data-aos="zoom-in"
        position={{ left: "29px", top: "-166px" }}
        src={star3}
        alt="star"
      />
      <PositionedStar
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        data-aos="zoom-in"
        position={{ left: "-66px", top: "0px" }}
        src={star4}
        alt="star"
      />
      <PositionedStar
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        data-aos="zoom-in"
        position={{ left: "-100px", bottom: "-125px" }}
        src={star1}
        alt="star"
      />
      <PositionedStar
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        data-aos="zoom-in"
        position={{ left: "-116px", top: "-166px" }}
        src={star3}
        alt="star"
      />
      <PositionedStar
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        data-aos="zoom-in"
        position={{ left: "-200px", top: "-166px" }}
        src={star2}
        alt="star"
      />
      <PositionedStar
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        data-aos="zoom-in"
        position={{ left: "-201px", bottom: "49px" }}
        src={star3}
        alt="star"
      />

      {/*  */}
      <PositionedStar
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        data-aos="zoom-in"
        position={{ right: "-26px", bottom: "252px" }}
        src={star2}
        alt="star"
      />
      <PositionedStar
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        data-aos="zoom-in"
        position={{ right: "29px", top: "-166px" }}
        src={star3}
        alt="star"
      />
      <PositionedStar
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        data-aos="zoom-in"
        position={{ right: "-80px", bottom: "90px" }}
        src={star4}
        alt="star"
      />

      <PositionedStar
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        data-aos="zoom-in"
        position={{ right: "-116px", top: "-166px" }}
        src={star3}
        alt="star"
      />
      <PositionedStar
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        data-aos="zoom-in"
        position={{ right: "-200px", top: "-166px" }}
        src={star2}
        alt="star"
      />
    </>
  );
};
const PositionedStar = styled("img")(({ theme, position }) => ({
  position: "absolute",
  opacity: "0.2",
  ...position,
}));

export default Stars;
