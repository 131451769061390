import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const CustomButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    background: "#afe2cf",
  },
}));
const ContainedButton = ({ text, width, height, ml, styles, onClick }) => {
  const buttonStyles = {
    color: "black",
    fontFamily: "Gilroy, sans-serif",
    width: width || "112px",
    height: height || "36px",
    flexShrink: 0,
    borderRadius: "20px",
    background: "#99EFD0",
    boxShadow:
      "-11.09000015258789px 18.1299991607666px 36.648292541503906px 0px #99efd045",
    cursor: "pointer",
    marginLeft: ml,
    transition: "0.5s",
    fontWeight: "bold",
    ...styles, // Можете передать дополнительные стили извне
  };

  return (
    <CustomButton type="submit" onClick={onClick} sx={buttonStyles}>
      {text}
    </CustomButton>
  );
};

export default ContainedButton;
