import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import burgerMenu from "svg/burgerMenu.svg";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";
import GroupsIcon from "@mui/icons-material/Groups";
import ContactsIcon from "@mui/icons-material/Contacts";
// import QuizIcon from "@mui/icons-material/Quiz";
import DevicesIcon from "@mui/icons-material/Devices";

export default function HeaderMenu() {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleNavigate = (path) => (e) => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    if (path === "Home") {
      navigate("/");
    } else if (path === "Our Team") {
      navigate("/ourteam");
    } else if (path === "About") {
      navigate("/about");
    } else if (path === "Services") {
      navigate("/services");
    }
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: "300px",
        backgroundColor: "#131313",
        height: "100%",
        color: "white",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["Home", "About", "Our Team", "Services"].map((text, index) => (
          <ListItem onClick={handleNavigate(text)} key={text} disablePadding>
            <ListItemButton sx={{ padding: "20px" }}>
              <ListItemIcon sx={{ color: "white" }}>
                {text === "Home" ? <HomeIcon /> : null}
                {text === "Our Team" ? <GroupsIcon /> : null}
                {text === "About" ? <ContactsIcon /> : null}
                {text === "Services" ? <DevicesIcon /> : null}
                {/* {text === "FAQ" ? <QuizIcon /> : null} */}
              </ListItemIcon>
              <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
                {text}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="right">
        <img
          onClick={toggleDrawer("right", true)}
          src={burgerMenu}
          alt="button burger menu"
        />

        <Drawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
