import { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import theme from "./theme";
import { Container, ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import i18n from "i18next";

import HomePage from "pages/Home";
// import ContactsPage from "pages/Contacts";
import OurTeam from "pages/OurTeam/OurTeam";
import MainLayout from "./layouts/MainLayout";
import AboutUs from "pages/AboutUs/AboutUs";
import Services from "pages/Services/Services";
import PageNotFound from "pages/PageNotFound/PageNotFound";
import { I18nextProvider } from "react-i18next";

const languageLocalStorage = localStorage.getItem("__ada:lang") || "en";

i18n.init({
  resources: {
    en: {
      translation: require("locales/en/translation.json"),
    },
    de: {
      translation: require("locales/de/translation.json"),
    },
  },
  lng: languageLocalStorage,
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <MainLayout>
            <Container maxWidth="xl">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/ourteam" element={<OurTeam />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/services" element={<Services />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </Container>
          </MainLayout>
        </ThemeProvider>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
