import { Box, Typography } from "@mui/material";
import ContainedButton from "components/Buttons/ContainedButton";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();
  const handleNavigate = (path) => (e) => {
    navigate(path);
  };
  return (
    <Box
      sx={{
        height: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography
          variant="h3"
          sx={{ fontFamily: "Gilroy, sans-serif", color: "white" }}
        >
          Oops...
        </Typography>
        <Typography
          sx={{ fontFamily: "Gilroy, sans-serif", color: "var(--main-color)" }}
          variant="h3"
        >
          Page Not Found
        </Typography>
        <ContainedButton
          onClick={handleNavigate("/")}
          width="200px"
          height="45px"
          styles={{ marginTop: "50px" }}
          text="homepage"
        />
      </Box>
    </Box>
  );
}
