import React from "react";

import { CompanyText, Logo, MainBox, NavigationBox, NavigationBoxInner, Text } from "./Styled";
import logo from "images/header/logo.png";
import { Box, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigate = (path) => (e) => {
    navigate(path);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <MainBox>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <NavigationBox>
            <Logo onClick={handleNavigate("/")} src={logo} alt="logo ada tech solution" />
            <NavigationBoxInner>
              <Text onClick={handleNavigate("/about")}>{t("About")}</Text>
              <Text onClick={handleNavigate("/ourteam")}>{t("Our Team")}</Text>
              <Text onClick={handleNavigate("/services")}>{t("Services")}</Text>
            </NavigationBoxInner>
            <CompanyText>ADA Tech Solution © 2024</CompanyText>
          </NavigationBox>
        </Box>
      </Container>
    </MainBox>
  );
};

export default Footer;
