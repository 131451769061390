import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const MainBox = styled(Box)(({ theme }) => ({
  marginTop: "80px",
  color: "white",
  marginBottom: "150px",
}));

// ----------------------------

export const HeroBox = styled(Box)(({ theme, image2 }) => ({
  width: "100%",
  height: "60vh",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundImage: `url(${image2})`,
  backgroundSize: "cover",
  backgroundPositionX: "right",
}));
export const HeroTitle = styled(Typography)(({ theme, image2 }) => ({
  fontFamily: "Gilroy, sans-serif",
  color: "var(--main-color)",
}));
export const HeroSubTitle = styled(Typography)(({ theme, image2 }) => ({
  fontFamily: "Gilroy, sans-serif",
  maxWidth: "650px",
}));

// ----------------------------

export const Mission = styled(Box)(({ theme, image2 }) => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  "@media all and (max-width: 850px)": {
    flexDirection: "column",
    alignItems: "center",
  },
}));
export const MissionTitle = styled(Typography)(({ theme, image2 }) => ({
  fontFamily: "Gilroy, sans-serif",
  color: "var(--main-color)",
  "@media all and (max-width: 850px)": {
    textAlign: "center",
  },
}));
export const MissionSubTitle = styled(Typography)(({ theme, image2 }) => ({
  fontFamily: "Gilroy, sans-serif",
  maxWidth: "450px",
  marginTop: "20px",
  "@media all and (max-width: 850px)": {
    textAlign: "center",
    marginBottom: "50px",
  },
}));
export const MissionBoxImg = styled(Box)(({ theme, image2 }) => ({
  marginLeft: "40px",
  "@media all and (max-width: 850px)": {
    marginLeft: "0",
  },
}));
export const MissionImg = styled("img")(({ theme, image2 }) => ({
  width: "100%",
  borderRadius: "50px",
}));

// ----------------------------

export const Photos = styled(Box)(({ theme, image2 }) => ({
  marginTop: "50px",
  display: "flex",
  justifyContent: "space-around",
  "@media all and (max-width: 850px)": {
    flexDirection: "column",
    alignItems: "center",
    marginTop: "10px",
  },
}));
export const PhotoBox = styled(Box)(({ theme, image2 }) => ({
  maxWidth: "550px",
  margin: "30px",
}));
export const Photo = styled("img")(({ theme, image2 }) => ({
  width: "100%",
  borderRadius: "50px",
}));

// ----------------------------

export const OurStory = styled(Box)(({ theme, image2 }) => ({
  textAlign: "center",
  marginTop: "50px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));
export const OurStoryText = styled(Typography)(({ theme, image2 }) => ({
  maxWidth: "600px",
  marginTop: "20px",
}));
