import React from "react";
import { useTranslation } from "react-i18next";
import {
  Comment,
  CommentBox,
  CommentBoxInner,
  CommentGroup,
  ImageBox,
  ImportantText,
  MainBox,
  MainImageBox,
  Name,
  PhotoBox,
  PhotoTitle,
  // SelectedWord,
  TextGroup,
  TextGroupSubtitle,
  TextGroupTitle,
} from "./Styled";

import emir from "images/team/emir.png";
import anas from "images/team/anas.png";
import becktur from "images/team/becktur.png";
import said from "images/team/said.png";
// import ilim from "images/team/ilim.png";
import aliya from "images/aliya.png";
import team2 from "images/team/team2.png";
import { Box } from "@mui/material";
import Form from "pages/Home/Sections/FormCardSection/Form";
import styled from "@emotion/styled";
import RoundImageWithLoading from "./Skeletons/RoundImageWithLoading";
import PhotoCircleBoxSkeleton from "./Skeletons/PhotoCircleBoxSkeleton";

export default function OurTeam() {
  const { t } = useTranslation();

  return (
    <>
      <MainBox>
        <TextGroup>
          <TextGroupTitle variant="h4">{t("team.title")}</TextGroupTitle>
          <TextGroupSubtitle>{t("team.subTitle")}</TextGroupSubtitle>
        </TextGroup>
        <MainImageBox>
          <ImageBox>
            <PhotoBox>
              <PhotoTitle>{t("team.mobileDev")}</PhotoTitle>
              <RoundImageWithLoading photo={emir} />
            </PhotoBox>
            <PhotoBox>
              <PhotoTitle>{t("team.frontEnd")}</PhotoTitle>
              <RoundImageWithLoading photo={becktur} />
            </PhotoBox>
            <PhotoBox>
              <PhotoTitle>{t("team.pm")}</PhotoTitle>
              <RoundImageWithLoading photo={said} />
            </PhotoBox>
            <PhotoBox>
              <PhotoTitle>{t("team.uxUi")}</PhotoTitle>
              <RoundImageWithLoading photo={aliya} />
            </PhotoBox>
            <PhotoBox>
              <PhotoTitle>{t("team.backEnd")}</PhotoTitle>
              <RoundImageWithLoading photo={anas} />
            </PhotoBox>
          </ImageBox>
        </MainImageBox>
        <ImportantText variant="h4">{t("team.mostImportant", { selectedWord: t("team.selectedWord") })}</ImportantText>
        <CommentBox width="100%">
          <CommentGroup>
            <CommentBoxInner height="135px">
              <PhotoCircleBoxSkeleton styles={{ backgroundSize: "330%", height: "72px" }} photo={anas} />
              <Comment>
                <Name>{t("team.ergeshovAnas")}</Name>
                {t("team.ergeshovAnasDesc")}
              </Comment>
            </CommentBoxInner>
            <CommentBoxInner height="135px">
              <PhotoCircleBoxSkeleton styles={{ backgroundSize: "330%", height: "74px" }} photo={becktur} />
              <Comment>
                <Name>{t("team.sadabaevBecktur")}</Name>
                {t("team.sadabaevBeckturDesc")}
              </Comment>
            </CommentBoxInner>
          </CommentGroup>

          <CommentGroup sx={{ marginTop: "65px" }}>
            <CommentBoxInner height="135px">
              <PhotoCircleBoxSkeleton photo={emir} />
              <Comment>
                <Name>{t("team.makeshovEmir")}</Name>
                {t("team.makeshovEmirDesc")}
              </Comment>
            </CommentBoxInner>
            <CommentBoxInner styles={{}} height="135px">
              <PhotoCircleBoxSkeleton styles={{ backgroundSize: "330%" }} photo={aliya} />
              <Comment>
                <Name>{t("team.aubakirovaAliya")}</Name>
                {t("team.aubakirovaAliyaDesc")}
              </Comment>
            </CommentBoxInner>
          </CommentGroup>
        </CommentBox>
        <Box sx={{ maxWidth: "1000px", marginTop: "50px" }}>
          <ImgBox src={team2} alt="ada team" />
        </Box>
      </MainBox>
      <Form />
    </>
  );
}

const ImgBox = styled("img")(({ theme }) => ({
  width: "100%",
  borderRadius: "50px",
}));
