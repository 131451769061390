import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PopUp from "components/PopUp/PopUp";
import Stars from "./Stars";
import "./Form.css";

export default function Form() {
  const { t } = useTranslation();

  return (
    <>
      <div className="form-card-block">
        <div className="form-card-info">
          <Box>
            <Typography fontSize="36px" sx={{ color: "#99EFD0", fontFamily: "Gilroy, sans-serif" }}>
              {t("translation.form.startProjectTitle")}
            </Typography>
            <Typography paddingBottom="40px" paddingTop="20px" fontSize="16px" sx={{ color: "white" }}>
              {t("translation.form.startProjectSubtitle")}
            </Typography>
          </Box>

          <PopUp width="157px" height="46px" text={t("translation.form.writeUsButton")} />
          <Typography fontSize="14px" paddingTop="20px" sx={{ color: "white", fontFamily: "Gilroy, sans-serif" }}>
            {t("translation.form.noSpamText")}
          </Typography>
          <Stars />
          <Typography sx={{ marginTop: "40px" }}>Business Development Representative</Typography>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
