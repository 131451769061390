import React, { useEffect, useState } from "react";
import { PhotoCircle } from "../Styled";
import { Skeleton } from "@mui/material";
import styled from "@emotion/styled";

const RoundImageWithLoading = ({ photo }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    const image = new Image();

    image.onload = handleImageLoad;
    image.onerror = handleImageError;

    image.src = photo;
  }, [photo]);

  return <>{isLoading ? <BigRoundPhotoSkeleton variant="circular" animation="wave" /> : <PhotoCircle img={photo} alt={photo} />}</>;
};

export default RoundImageWithLoading;

export const BigRoundPhotoSkeleton = styled(Skeleton)(({ theme, styles }) => ({
  background: "#ffffff21",
  width: "150px",
  height: "150px",
  "@media all and (max-width: 1050px)": {},
  "@media all and (max-width: 590px)": {
    width: "90px",
    height: "90px",
  },
}));
