import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const MainBox = styled("footer")(({ theme, styles }) => ({
  color: "white",
  backgroundImage: "linear-gradient(0deg, #40896e8f 0%, #000000 100%)",
  marginTop: "50px",
}));

export const NavigationBox = styled(Box)(({ theme, styles }) => ({
  textAlign: "center",
  width: "100%",
  display: " flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const Logo = styled("img")(({ theme, styles }) => ({
  maxWidth: "100px",
  cursor: "pointer",
}));

export const NavigationBoxInner = styled("nav")(({ theme, styles }) => ({
  maxWidth: "350px",
  display: "flex",
  justifyContent: "space-between",
  margin: "20px 0",
  width: "80%",
}));

export const Text = styled(Typography)(({ theme, styles }) => ({
  fontFamily: "Gilroy, sans-serif",
  cursor: "pointer",
  ...styles,
  "@media all and (max-width: 390px)": {},
}));

export const CompanyText = styled(Typography)(({ theme, styles }) => ({
  marginBottom: "50px",
}));

export const ContactForm = styled(Box)(({ theme, styles }) => ({
  display: "flex",
}));

export const Form = styled("form")(({ theme, styles }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
}));

export const Input = styled("input")(({ theme, styles }) => ({
  borderRadius: "5px",
  border: "none",
  background: "#f6f6f6",
  width: "308px",
  height: "56px",
  paddingLeft: "10px",
  marginBottom: "30px",
  ...styles,
}));
