import React from "react";

import {
  BoxForCards,
  CardBackground,
  CardBackgroundBox,
  CardSubtitle,
  CardTitle,
  IconTechnology,
  MyCard,
  SecondSection,
  SecondSectionInner,
  TechnologyBox,
  TechnologyBoxInner,
  Title,
} from "./styled";

import reactIcon from "svg/react.svg";
import flutterIcon from "svg/flutter.svg";
import iphoneIcon from "svg/iphone.svg";
import androidIcon from "svg/android.svg";
import swiftIcon from "svg/swift.svg";
import jsIcon from "svg/js.svg";
import vueIcon from "svg/vue.svg";
import nodeIcon from "svg/nodejs.svg";
import figmaIcon from "svg/figma.svg";
import psIcon from "svg/ps.svg";
import aiIcon from "svg/ai.svg";
import aeIcon from "svg/ae.svg";
import cinemaIcon from "svg/cinema4d.svg";
import mobileBackground from "images/appBackground.png";
import webBackground from "images/webBackground.png";
import designIcon from "images/designBackground.png";
import { useTranslation } from "react-i18next";

export default function TwoSections() {

  const { t } = useTranslation("");
  return (
    <SecondSection>
      <SecondSectionInner>
      <Title>{t("sections.services.webDevelopment.title")}</Title>
      </SecondSectionInner>
      <BoxForCards>
        <MyCard>
        <CardTitle>{t("sections.services.webDevelopment.title")}</CardTitle>
        <CardSubtitle>{t("sections.services.webDevelopment.subtitle")}</CardSubtitle>

          <TechnologyBox>
            <TechnologyBoxInner>
              <IconTechnology src={jsIcon} />
            </TechnologyBoxInner>
            <TechnologyBoxInner>
              <IconTechnology src={reactIcon} />
            </TechnologyBoxInner>
            <TechnologyBoxInner>
              <IconTechnology src={vueIcon} />
            </TechnologyBoxInner>
            <TechnologyBoxInner>
              <IconTechnology src={nodeIcon} />
            </TechnologyBoxInner>
          </TechnologyBox>
          <CardBackgroundBox
            styles={{
              maxWidth: "380px",
              top: "0",
              right: "0",
            }}
          >
            <CardBackground src={webBackground} />
          </CardBackgroundBox>
        </MyCard>
        <MyCard>
        <CardTitle>{t("sections.services.mobileAppDevelopment.title")}</CardTitle>
        <CardSubtitle>{t("sections.services.mobileAppDevelopment.subtitle")}</CardSubtitle>
          <TechnologyBox>
            <TechnologyBoxInner>
              <IconTechnology src={reactIcon} />
            </TechnologyBoxInner>
            <TechnologyBoxInner>
              <IconTechnology src={flutterIcon} />
            </TechnologyBoxInner>
            <TechnologyBoxInner>
              <IconTechnology src={iphoneIcon} />
            </TechnologyBoxInner>
            <TechnologyBoxInner>
              <IconTechnology src={swiftIcon} />
            </TechnologyBoxInner>
            <TechnologyBoxInner>
              <IconTechnology src={androidIcon} />
            </TechnologyBoxInner>
          </TechnologyBox>
          <CardBackgroundBox
            styles={{ maxWidth: "315px", bottom: "-3px", right: "0" }}
          >
            <CardBackground src={mobileBackground} />
          </CardBackgroundBox>
        </MyCard>
        <MyCard>
        <CardTitle>{t("sections.services.uxUiDesign.title")}</CardTitle>
        <CardSubtitle>{t("sections.services.uxUiDesign.subtitle")}</CardSubtitle>
          <TechnologyBox>
            <TechnologyBoxInner>
              <IconTechnology src={figmaIcon} />
            </TechnologyBoxInner>
            <TechnologyBoxInner>
              <IconTechnology src={aiIcon} />
            </TechnologyBoxInner>
            <TechnologyBoxInner>
              <IconTechnology src={aeIcon} />
            </TechnologyBoxInner>
            <TechnologyBoxInner>
              <IconTechnology src={psIcon} />
            </TechnologyBoxInner>
            <TechnologyBoxInner>
              <IconTechnology src={cinemaIcon} />
            </TechnologyBoxInner>
          </TechnologyBox>
          <CardBackgroundBox
            styles={{ maxWidth: "275px", top: "0", right: "0" }}
          >
            <CardBackground src={designIcon} />
          </CardBackgroundBox>
        </MyCard>
      </BoxForCards>
    </SecondSection>
  );
}
