import React, { useEffect, useState } from "react";
import { PhotoCircleBox } from "../Styled";
import { Skeleton } from "@mui/material";
import styled from "@emotion/styled";

const PhotoCircleBoxSkeleton = ({ photo, styles }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    const image = new Image();

    image.onload = handleImageLoad;
    image.onerror = handleImageError;

    image.src = photo;
  }, [photo]);

  return (
    <>
      {isLoading ? (
        <MiniRoundPhotoSkeleton variant="circular" animation="wave" />
      ) : (
        <PhotoCircleBox styles={styles} img={photo} alt={photo} />
      )}
    </>
  );
};

export default PhotoCircleBoxSkeleton;

export const MiniRoundPhotoSkeleton = styled(Skeleton)(({ theme }) => ({
  width: "80px",
  height: "80px",
}));
