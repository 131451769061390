import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const PhotoCircle = styled(Box)(({ theme, img, styles }) => ({
  width: "150px",
  height: "150px",
  borderRadius: "100px",
  backgroundSize: "250%",
  backgroundPositionX: "50%",
  backgroundImage: `url(${img})`,
  transition: "0.7s",
  cursor: "pointer",
  border: "3px solid var(--main-color)",

  ...styles,
  "&:hover": {
    height: "200px",
    backgroundPositionY: "33.33%",
  },

  "@media all and (max-width: 1050px)": {
    "&:hover": {
      height: "150px",
    },
  },
  "@media all and (max-width: 590px)": {
    width: "90px",
    height: "90px",
    "&:hover": {
      height: "120px",
    },
  },
}));

export const MainImageBox = styled(Box)(({ theme, styles }) => ({
  width: "100%",

  "@media all and (max-width: 1050px)": {
    display: "flex",
    justifyContent: "space-around",
  },
}));

export const PhotoBox = styled(Box)(({ theme, styles }) => ({
  textAlign: "center",
  height: "340px",
  "@media all and (max-width: 1000px)": {
    height: "auto",
  },
}));
export const PhotoTitle = styled("p")(({ theme, styles }) => ({
  color: "white",
  fontFamily: "Gilroy, sans-serif",
  marginBottom: "20px",
  ...styles,
}));

export const MainBox = styled(Box)(({ theme, styles }) => ({
  marginTop: "80px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  marginBottom: "100px",
  ...styles,
}));

export const TextGroup = styled(Box)(({ theme, styles }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  ...styles,
}));

export const TextGroupTitle = styled(Typography)(({ theme, styles }) => ({
  color: "var(--main-color)",
  fontFamily: "Gilroy, sans-serif",
  ...styles,
}));

export const TextGroupSubtitle = styled(Typography)(({ theme, styles }) => ({
  color: "white",
  fontFamily: "Gilroy, sans-serif",
  ...styles,
}));

export const ImageBox = styled(Box)(({ theme, styles }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  transition: "0.7s",
  gap: "50px",
  marginTop: "50px",
  ...styles,
  "@media all and (max-width: 1000px)": {
    marginBottom: "50px",
    justifyContent: "center",
  },
  "@media all and (max-width: 600px)": {
    maxWidth: "90%",
  },
}));

export const ImportantText = styled(Typography)(({ theme, styles }) => ({
  color: "var(--main-color)",
  fontFamily: "Gilroy, sans-serif",
  textAlign: "center",
  ...styles,
}));

export const SelectedWord = styled("span")(({ theme, styles }) => ({
  color: "white",
  ...styles,
}));

export const PhotoCircleBox = styled(Box)(({ theme, img, styles }) => ({
  width: "80px",
  height: "80px",
  borderRadius: "100px",
  backgroundSize: "315%",
  backgroundPositionX: "50%",
  backgroundPositionY: "10%",
  backgroundImage: `url(${img})`,
  transition: "0.7s",
  cursor: "pointer",

  ...styles,
}));

export const CommentBox = styled(Box)(({ theme, styles }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "50px",
  ...styles,
}));

export const CommentBoxInner = styled(Box)(({ theme, styles, height }) => ({
  background: "white",
  padding: "10px",
  borderRadius: "20px",
  display: "flex",
  alignItems: "center",
  height: height,
  boxShadow: "15px 4px 36.648292541503906px 0px #99efd09e",

  ...styles,
  "@media all and (max-width: 900px)": {
    maxWidth: "420px",
    marginTop: "0",
    marginBottom: "40px",
  },
  "@media all and (max-width: 500px)": {
    maxWidth: "350px",
    marginBottom: "40px",
  },
}));

export const Comment = styled(Box)(({ theme, styles }) => ({
  maxWidth: "300px",
  marginLeft: "15px",
  fontFamily: "Gilroy, sans-serif",
  fontWeight: "100",
  fontSize: "15px",
  marginTop: "10px",
  lineHeight: "21px",
  ...styles,
  "@media all and (max-width: 500px)": {
    maxWidth: "220px",
    fontSize: "12px",
    lineHeight: "13px",
  },
}));

export const Name = styled(Typography)(({ theme, styles }) => ({
  fontWeight: "bold",
  fontFamily: "Inter, sans-serif",

  ...styles,
}));

export const CommentGroup = styled(Box)(({ theme, styles }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-around",
  ...styles,
  "@media all and (max-width: 900px)": {
    flexDirection: "column",
    alignItems: "center",
  },
}));
