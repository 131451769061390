import React from "react";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

export default function FourthSection() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        marginTop: "200px",
        marginBottom: "200px",
      }}
      id="about-section"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Title>{t("fourthSection.title")}</Title>
      </Box>
      <MainBoxTitles>
        <BoxTitles>
          <Title data-aos="zoom-in">100+</Title>
          <SecondSubtitle>{t("fourthSection.subtitle1")}</SecondSubtitle>
        </BoxTitles>
        <BoxTitles>
          <Title data-aos="zoom-in">15% {t("common.off")}</Title>
          <SecondSubtitle>{t("fourthSection.subtitle2")}</SecondSubtitle>
        </BoxTitles>
        <BoxTitles>
          <Title data-aos="zoom-in">30+</Title>
          <SecondSubtitle>{t("fourthSection.subtitle3")}</SecondSubtitle>
        </BoxTitles>
      </MainBoxTitles>
    </Box>
  );
}

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "36px",
  fontFamily: "Inter, sans-serif",
  fontWeight: "bold",
  lineHeight: "42px",
  color: "#99EFD0",
}));

const SecondSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: "15px",
  fontFamily: "Gilroy, sans-serif",
  lineHeight: "20px",
  color: "white",
  marginTop: "15px",
}));

const BoxTitles = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  width: "340px",
  "@media all and (max-width: 900px)": {
    marginBottom: "50px",
  },
}));

const MainBoxTitles = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "50px",
  "@media all and (max-width: 900px)": {
    flexDirection: "column",
    alignItems: "center",
  },
}));
