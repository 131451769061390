import React from "react";

import "./main.css";
import "./Home.css";

import MainSection from "pages/Home/SectionsByEmir/MainSection";
import SecondSection from "pages/Home/SectionsByEmir/SecondSection";
import ThirdSection from "pages/Home/SectionsByEmir/ThirdSection";
import FourthSection from "pages/Home/SectionsByEmir/FourthSection";
import ProjectSection from "./Sections/ProjectSection/Project";
import InvestSection from "./Sections/InvestSection/Invest";
import FormSection from "./Sections/FormCardSection/Form";

const index = () => {
  return (
    <>
      <MainSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <ProjectSection />
      <InvestSection />
      <FormSection />
    </>
  );
};

export default index;
