import React from "react";
import Header from "components/Header/Header";
import "./main.css";
import Footer from "components/Footer/Footer";

const MainLayout = (props) => {
  return (
    <>
      <Header />
      <div className="main">{props.children}</div>
      <Footer />
    </>
  );
};

export default MainLayout;
