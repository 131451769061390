import { useRef, useState } from "react";
import * as React from "react";
import { Box, Link, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import styled from "@emotion/styled";

import OutlinedButton from "components/Buttons/OutlinedButton";
import ContainedButton from "components/Buttons/ContainedButton";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopUp({ styles, text, width, height }) {
  const form = useRef();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [isValid, setIsValid] = useState(false);
  const { t } = useTranslation();

  const errorAlert = (text) => {
    toast.error(text ? text : "Please enter your email address.", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const successAlert = () => {
    toast.success("Data Successfully Sent!", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    const newIsValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail);
    setEmail(newEmail);
    setIsValid(newIsValid);
  };
  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);
  };
  const handleMessageChange = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);
  };

  const sendEmail = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!email.trim() || !name.trim() || !message.trim()) {
      errorAlert("Fill in all fields!");
      return;
    }

    emailjs.sendForm("service_urgator", "template_42zbj3c", form.current, "iXzEW-CyRSj-0beUO").then(
      (result) => {
        successAlert();
        setEmail("");
        setName("");
        setMessage("");
      },
      (error) => {
        console.log(error);
        errorAlert(`Error! Status ${error.status}`);
        setEmail("");
        setName("");
        setMessage("");
      }
    );
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <OutlinedButton text={text} width={width} height={height} onClick={handleClickOpen} styles={styles} />
      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
        <Box sx={{ margin: "50px", maxWidth: "400px" }}>
          <CloseIcon
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: "20px",
              top: "20px",
              cursor: "pointer",
            }}
          />
          <Typography
            sx={{
              color: "black",
              fontFamily: "Gilroy, sans-serif",
              fontSize: "25px",
            }}
          >
            {t("popup.haveProject")}
          </Typography>
          <Typography sx={{ marginBottom: "50px" }}>{t("popup.dropUsLine")}</Typography>

          <Box>
            <FormEmail ref={form} onSubmit={sendEmail}>
              <StyledTextField
                sx={{ marginBottom: "15px" }}
                name="user_name"
                type="name"
                value={name}
                onChange={handleNameChange}
                id="standard-basic"
                label={t("form.name") + "*"}
                variant="standard"
              />

              <StyledTextField
                sx={{ marginBottom: "15px" }}
                onChange={handleEmailChange}
                value={email}
                name="user_email"
                type="email"
                placeholder="example@gmail.com"
                id="standard-basic"
                label={t("form.email") + "*"}
                variant="standard"
              />
              <StyledTextField
                sx={{ marginBottom: "15px" }}
                onChange={handleMessageChange}
                value={message}
                name="message"
                type="text"
                id="standard-basic"
                label={t("form.message") + "*"}
                variant="standard"
              />

              {email && !isValid ? (
                <Typography
                  sx={{
                    color: "#ff0000b8",
                    fontFamily: "Gilroy, sans-serif",
                    opacity: 1,
                    transition: "opacity 0.5s",
                    marginBottom: "15px",
                  }}
                >
                  {t("form.invalidEmail")}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: "#ff0000b8",
                    fontFamily: "Gilroy, sans-serif",
                    opacity: 0,
                    transition: "opacity 0.5s",
                    marginBottom: "15px",
                  }}
                >
                  {t("form.invalidEmail")}
                </Typography>
              )}

              <ContainedButton text={t("form.send")} styles={BtnStlyes} />
            </FormEmail>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: "5px",
              marginTop: "30px",
              fontSize: "14px",
            }}
          >
            <Typography sx={{ color: "black", fontFamily: "Gilroy, sans-serif", fontSize: "14px" }}>Said O.</Typography>
            <Link sx={{ color: "black", fontFamily: "Gilroy, sans-serif" }} href="tel:+491785025560">
              +491 785 02-55-60
            </Link>
          </Box>
          <Link
            variant="subtitle1"
            href="mailto: said.ada@outlook.com"
            sx={{ fontSize: "14px", color: "black", cursor: "pointer", fontFamily: "Gilroy, sans-serif", textAlign: "center", display: "block" }}
          >
            said.ada@outlook.com
          </Link>
        </Box>
      </Dialog>
    </>
  );
}
const StyledTextField = styled(TextField)(({ theme }) => ({
  borderRadius: "5px",
  width: "100%",
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "black",
    },
  },
}));
const FormEmail = styled("form")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  flexDirection: "column",
  alignItems: "center",
}));

const BtnStlyes = {
  borderRadius: "5px",
  height: "56px",
  width: "310px",
  fontSize: "16px",
  background: "black",
  color: "white",
  "&:hover": {
    background: "black",
  },
  "@media all and (max-width: 600px)": {
    width: "100%",
  },
};
