import React from "react";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ContainedButton from "components/Buttons/ContainedButton";
import chart from "svg/chart.svg";
import chart2 from "svg/chart2.svg";
import chart3 from "svg/chart3.svg";
import image from "images/MainPage/cardThirdSection.png";

const ThirdSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = (path) => (e) => {
    navigate(path);
    window.scrollTo({
      top: 0,
      left: 0,
    });
  };

  return (
    <Box sx={{ marginTop: "150px" }}>
      <MyBox>
        <Box data-aos="fade-right">
          <Title>
            {t("thirdSection.titlePart1")}
            <WordTraffik>{t("thirdSection.titlePart2")}</WordTraffik>
          </Title>
          <SubTitle>{t("thirdSection.subtitle")}</SubTitle>
          <ContainedButton
            onClick={handleNavigate("/services")}
            text={t("thirdSection.buttonText")}
            width="155px"
            height="48px"
          />
        </Box>
        <ContentBox>
          <Image
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
            src={image}
            alt="documents"
          />
          <SvgPosition
            data-aos="fade-right"
            data-aos-anchor-placement="top-center"
            styles={{ left: "-100px", top: "17px" }}
            mw={{ maxWidth: "160px", left: "-75px" }}
            src={chart}
            alt="svg"
          />
          <SvgPosition
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
            styles={{ left: "-20px", bottom: "-90px" }}
            src={chart2}
            alt="svg"
          />
          <SvgPosition
            data-aos="fade-left"
            data-aos-anchor-placement="top-center"
            styles={{ right: "-45px", bottom: "-50px", zIndex: "-1" }}
            src={chart3}
            alt="svg"
          />

          <SvgPositionMobile
            data-aos="fade-right"
            data-aos-anchor-placement="top-center"
            styles={{ left: "-60px", top: "17px", maxWidth: "130px" }}
            src={chart}
            alt="svg"
          />
          <SvgPositionMobile
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
            styles={{ left: "20px", bottom: "-50px", maxWidth: "100px" }}
            src={chart2}
            alt="svg"
          />
          <SvgPositionMobile
            data-aos="fade-right"
            data-aos-anchor-placement="top-center"
            styles={{ right: "-20px", bottom: "-50px", zIndex: "-1" }}
            src={chart3}
            alt="svg"
          />
        </ContentBox>
      </MyBox>
    </Box>
  );
};

export default ThirdSection;

const Title = styled(Typography)(({ theme }) => ({
  color: "#99EFD0",
  fontSize: "42px",
  lineHeight: "50px",
  fontFamily: "Inter, sans-serif",
  maxWidth: "470px",
  fontWeight: "700",
}));

const WordTraffik = styled("span")(({ theme }) => ({
  color: "white",
  fontSize: "42px",
  lineHeight: "50px",
  fontFamily: "Inter, sans-serif",
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  color: "white",
  fontSize: "15px",
  lineHeight: "28px",
  fontFamily: "Gilroy, sans-serif",
  maxWidth: "470px",
  marginTop: "30px",
  marginBottom: "40px",
}));

const SvgPosition = styled("img")(({ theme, styles, mw }) => ({
  position: "absolute",
  ...styles,
  "@media all and (max-width: 900px)": {
    ...mw,
  },
  "@media all and (max-width: 600px)": {
    display: "none",
  },
}));
const SvgPositionMobile = styled("img")(({ theme, styles }) => ({
  display: "none",
  position: "absolute",
  "@media all and (max-width: 600px)": {
    display: "inline-block",
    ...styles,
  },
}));
const Image = styled("img")(({ theme, styles }) => ({
  "@media all and (max-width: 600px)": {
    maxWidth: "205px",
  },
}));
const MyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  "@media all and (max-width: 900px)": {
    flexDirection: "column",
    textAlign: "center",
  },
}));
const ContentBox = styled(Box)(({ theme }) => ({
  position: "relative",
  "@media all and (max-width: 900px)": {
    marginTop: "100px",
  },
}));

// max-width: 205px;

// left: -65px;
//     top: 17px;
//     max-width: 116px;

// left: -20px;
// bottom: -53px;
// max-width: 100px;

// left: -20px;
// bottom: -53px;
// max-width: 100px;

// right: -29px;
//     bottom: -50px;
