import React from "react";
import { useTranslation } from "react-i18next"; 

import {
  FirstSection,
  FirstSectionInner,
  MainSubtitle,
  MainTitle,
  ServicesBox,
} from "./styled";

import FourSection from "./FourSection";
import TwoSections from "./TwoSections";
import Form from "pages/Home/Sections/FormCardSection/Form";
import PopUp from "components/PopUp/PopUp";

export default function Services() {
  const { t } = useTranslation(); 

  return (
    <ServicesBox>
      <FirstSection>
        <FirstSectionInner>
          <MainTitle variant="h1">
            {t("services.webAppDevelopmentTitle")} 
          </MainTitle>
          <MainSubtitle>
            {t("services.webAppDevelopmentSubtitle")} 
          </MainSubtitle>
          <PopUp width="200px" height="50px" text={t("services.discussYourIdea")} /> 
        </FirstSectionInner>
      </FirstSection>
      <TwoSections />
      <FourSection />
      <Form />
    </ServicesBox>
  );
}
