import React, { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import logo from "../../images/header/logo.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./header.css";
import HeaderMenu from "./HeaderMenu";

const Header = () => {
  const languageLocalStorage = localStorage.getItem("__ada:lang");
  const navigate = useNavigate();
  const [scrollTop, setScrollTop] = useState("scroll-top");
  const [currentLang, setCurrentLang] = useState(languageLocalStorage || "en");
  console.log("currentLang: ", currentLang);

  const handleLangChange = (e) => {
    if (currentLang === "en") {
      setCurrentLang("de");
      i18n.changeLanguage("de");
      localStorage.setItem("__ada:lang", "de");
    } else {
      setCurrentLang("en");
      i18n.changeLanguage("en");
      localStorage.setItem("__ada:lang", "en");
    }
  };

  const { t, i18n } = useTranslation();

  const listenScrollEvent = () => {
    window.scrollY > 200
      ? setScrollTop("scroll-top-active")
      : setScrollTop("scroll-top");
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const handleNavigate = (path) => (e) => {
    navigate(path);
    window.scrollTo({
      top: 0,
      left: 0,
    });
  };

  return (
    <header className={`main_header ${scrollTop}`}>
      <Container maxWidth="xl">
        <Box className="header">
          <Box className="logo_nav">
            <Box className="logo-box">
              <img onClick={handleNavigate("/")} src={logo} alt="logo ada" />
            </Box>
            <nav className="nav">
              <Typography onClick={handleNavigate("/about")}>
                {t("About")}
              </Typography>
              <Typography onClick={handleNavigate("/ourteam")}>
                {t("Our Team")}
              </Typography>
              <Typography onClick={handleNavigate("/services")}>
                {t("Services")}
              </Typography>
            </nav>
          </Box>

          <Box className="burger_menu">
            <Box sx={{ display: "flex" }} onClick={handleLangChange}>
              <Typography
                sx={{
                  color: i18n.language === "de" ? "var(--main-color)" : "white",
                  cursor: "pointer",
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginRight: "5px",
                }}
              >
                de
              </Typography>
              <Typography
                sx={{
                  color: i18n.language === "en" ? "var(--main-color)" : "white",
                  cursor: "pointer",
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingLeft: "5px",
                  marginRight: "20px",
                  borderLeft: "2px solid var(--main-color)",
                }}
                x
              >
                en
              </Typography>
            </Box>
            <HeaderMenu />
          </Box>
        </Box>
      </Container>
    </header>
  );
};

export default Header;
