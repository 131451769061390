import React from "react";
import {
  HeroBox,
  HeroSubTitle,
  HeroTitle,
  MainBox,
  Mission,
  MissionBoxImg,
  MissionImg,
  MissionSubTitle,
  MissionTitle,
  OurStory,
  OurStoryText,
  Photo,
  PhotoBox,
  Photos,
} from "./styled";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next"; 
import image2 from "images/backgroundAbout.gif";
import image3 from "images/team.png";
import image4 from "images/trio.png";
import bekanas from "images/bekanas.png";
import CustomizedSteppers from "./Stepper";
import Form from "pages/Home/Sections/FormCardSection/Form";

export default function AboutUs() {
  const { t } = useTranslation(); 

  return (
    <MainBox>
      <HeroBox image2={image2}>
        <HeroTitle variant="h3">{t("aboutUs.title")}</HeroTitle>
        <HeroSubTitle>{t("aboutUs.subTitle")}</HeroSubTitle>
      </HeroBox>
      <Mission>
        <Box>
          <MissionTitle variant="h3">{t("aboutUs.mission.title")}</MissionTitle>
          <MissionSubTitle>{t("aboutUs.mission.subTitle")}</MissionSubTitle>
        </Box>
        <MissionBoxImg>
          <Box maxWidth="500px">
            <MissionImg src={image3} alt="space background" />
          </Box>
        </MissionBoxImg>
      </Mission>
      <CustomizedSteppers />
      <Photos>
        <PhotoBox>
          <Photo src={bekanas} alt="two mens becktur anas " />
        </PhotoBox>
        <PhotoBox>
          <Photo src={image4} alt="trio three mens becktur anas emir" />
        </PhotoBox>
      </Photos>
      <OurStory>
        <HeroTitle variant="h5">{t("aboutUs.story.title")}</HeroTitle>
        <Typography maxWidth="600px">{t("aboutUs.story.paragraph1")}</Typography>
        <OurStoryText>{t("aboutUs.story.paragraph2")}</OurStoryText>
        <OurStoryText>{t("aboutUs.story.paragraph3")}</OurStoryText>
      </OurStory>
      <Form />
    </MainBox>
  );
}
