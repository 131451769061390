import React from "react";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Rectangle3 from "../images/Rectangle 3.png";
import Rectangle2 from "../images/rectangle2.svg";
import star1 from "svg/star1.svg";
import star2 from "svg/star2.svg";
import star3 from "svg/star3.svg";
import star4 from "svg/star4.svg";
import "./Invest.css";
import { styled } from "styled-components";

export default function Invest() {
  const { t } = useTranslation();

  return (
    <div className="invest-block">
      <div>
        <Typography fontWeight="bold" fontSize="36px" className="invest">
          {t("invest.titlePart1")} <br />
          <span style={{ color: "white" }}>{t("invest.titlePart2")}</span>
        </Typography>
        <Typography width="300px" marginTop="40px" className="invest-text">
          {t("invest.subtitle")}
        </Typography>
      </div>
      <div className="invest-card-block">
        <img src={Rectangle3} alt="Rectangle 3" className="rectangle-img" />
        <img src={Rectangle2} alt="Rectangle" className="rectangle2-img" />

        <div className="invest-mini-card">
          <Typography className="mini-card-text" paddingTop="10px" fontSize="16px">
            {t("invest.miniCardAdditionally")}
          </Typography>
          <Typography className="mini-card-text" fontSize="10px">
            {t("invest.miniCardEarned")} 12%
          </Typography>
        </div>
        <div className="invest-card">
          <div className="invest-card-info">
            <Typography sx={{ color: "#212B36" }} fontWeight="bold" fontSize="16px" className="invest-card-title">
              {t("invest.cardTitle")}
            </Typography>
            <Typography sx={{ color: "#637381" }} fontWeight="bold" fontSize="10px" className="invest-card-title">
              {t("invest.cardSubtitle")}
            </Typography>
            <Typography
              sx={{ color: "#13C296", paddingTop: "30px" }}
              fontWeight="bold"
              fontSize="24px"
              className="invest-card-title"
            >
            </Typography>
            <Typography
              sx={{ color: "#98A4AF" }}
              fontWeight="bold"
              fontSize="10px"
              className="invest-card-title"
            >
              YEARLY STATISTICS
            </Typography>
            <hr />
            <div className="invest-mini-block">
              <Typography
                paddingTop="10px"
                lineHeight="1"
                fontSize="14px"
                fontWeight="bold"
                sx={{ color: "#212B36" }}
              >
                13% <br /> <span className="invest-mini-text">April</span>
              </Typography>
              <Typography
                paddingTop="10px"
                lineHeight="1"
                fontSize="14px"
                fontWeight="bold"
                sx={{ color: "#212B36" }}
              >
                68% <br /> <span className="invest-mini-text">july</span>
              </Typography>
              <Typography
                paddingTop="10px"
                lineHeight="1"
                fontSize="14px"
                fontWeight="bold"
                sx={{ color: "#212B36" }}
              >
                45% <br /> <span className="invest-mini-text">august</span>
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <Box>
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ left: "-160px", bottom: "100px" }}
          src={star1}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ left: "-10px", top: "-187px" }}
          src={star4}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ left: "-88px", bottom: "252px" }}
          src={star2}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ left: "29px", top: "-166px" }}
          src={star3}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ left: "-66px", top: "0px" }}
          src={star4}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ left: "-80px", bottom: "420px" }}
          src={star1}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ left: "-116px", top: "-166px" }}
          src={star3}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ left: "-200px", top: "-166px" }}
          src={star2}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ left: "-201px", bottom: "49px" }}
          src={star3}
          alt="star"
        />

        {/*  */}
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ right: "-26px", bottom: "252px" }}
          src={star2}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ right: "29px", top: "-166px" }}
          src={star3}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ right: "-80px", bottom: "90px" }}
          src={star4}
          alt="star"
        />

        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ right: "-116px", top: "-166px" }}
          src={star3}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ right: "-200px", top: "-166px" }}
          src={star2}
          alt="star"
        />
      </Box>
    </div>
  );
}
const PositionedStar = styled("img")(({ theme, position }) => ({
  position: "absolute",
  opacity: "0.2",
  ...position,
}));
