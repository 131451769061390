import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Импорт хука для переводов
import Runner from "svg/Runner";
import company1 from "svg/company1.svg";
import company2 from "svg/company2.svg";
import company3 from "svg/company3.svg";
import company4 from "svg/company4.svg";
import company5 from "svg/company5.svg";
import company6 from "svg/company6.svg";
import effect from "images/effect.png";
import ContainedButton from "components/Buttons/ContainedButton";
import PopUp from "components/PopUp/PopUp";
import styled from "@emotion/styled";

const MainSection = () => {
  const navigate = useNavigate();
  const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation(); // Подключение хука для переводов

  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    setMouseCoords({ x: clientX, y: clientY });
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleNavigate = (path) => (e) => {
    navigate(path);
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box>
      {windowWidth >= 900 && (
        <Box
          sx={{
            position: "absolute",
            left: "0",
            right: "0",
            top: "0",
            bottom: "0",
            backgroundImage: `url(${effect})`,
            backgroundSize: "cover",
            zIndex: "-2",
            opacity: "0.2",
            clipPath: `ellipse(80px 80px at ${mouseCoords.x}px ${mouseCoords.y}px)`,
          }}
        ></Box>
      )}
      <MainWrapper>
        <Box>
          <TextWrapper>
            <MainTitle variant="h1">
              {t("Unleash Your Potential Elevate Your Business to New Heights!")}
            </MainTitle>
            <Subtitle>
              {t("Explore Cutting-Edge Solutions and Propel Your Business Forward into the Future.")}
            </Subtitle>
          </TextWrapper>
          <Box>
            <ContainedButton
              onClick={handleNavigate("/services")}
              styles={{ marginTop: "40px" }}
              width="157px"
              height="46px"
              text={t("Our Services")}
            />

            <PopUp
              width="157px"
              height="46px"
              text={t("Write Us")}
              styles={{ marginLeft: "20px", marginTop: "40px" }}
            />
          </Box>
        </Box>

        <RunnerImgWrapper>
          <Runner alt="runner svg" />
        </RunnerImgWrapper>
      </MainWrapper>
      <LogosWrapper>
        <Logo src={company1} alt={t("it company profitwell")} />
        <Logo src={company2} alt={t("it company Apppcues")} />
        <Logo src={company3} alt={t("it company shipbob")} />
        <Logo src={company4} alt={t("it company bench")} />
        <Logo src={company5} alt={t("it company subbly")} />
        <Logo src={company6} alt={t("it company demio")} />
      </LogosWrapper>
    </Box>
  );
};

export default MainSection;

const MainWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  "@media all and (max-width: 900px)": {
    justifyContent: "center",
    textAlign: "center",
  },
}));

const TextWrapper = styled(Box)(({ theme }) => ({
  maxWidth: "530px",
  marginTop: "100px",
  "@media all and (max-width: 800px)": {
    marginTop: "150px",
  },
}));
const MainTitle = styled(Typography)(({ theme }) => ({
  fontSize: "44px",
  fontFamily: 'Gilroy, "sans-serif"',
  color: "#99EFD0",
  "@media all and (max-width: 500px)": {
    fontSize: "35px",
  },
}));
const Subtitle = styled(Typography)(({ theme }) => ({
  maxWidth: "460px",
  fontSize: "18px",
  color: "white",
  fontFamily: 'Gilroy, "sans-serif"',
  marginTop: "20px",
}));
export const RunnerImgWrapper = styled(Box)(({ theme }) => ({
  marginTop: "50px",
  zIndex: "-2",
  "@media all and (max-width: 1050px)": {
    maxWidth: "490px",
    position: "relative",
  },
}));

export const LogosWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "50px",
  "@media all and (max-width: 800px)": {
    flexWrap: "wrap",
    justifyContent: "center",
  },
}));
export const Logo = styled("img")(({ theme }) => ({
  opacity: "0.3",
  transition: "0.5s",
  cursor: "pointer",

  "&:hover": {
    opacity: "0.7",
  },
  "@media all and (max-width: 800px)": {
    margin: "10px",
    maxWidth: "60px",
  },
}));
