import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import background from "images/background2.gif";
export const ServicesBox = styled(Box)(({ theme, styles }) => ({
  color: "white",
}));

export const FirstSection = styled(Box)(({ theme, styles }) => ({
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  // height: "500px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
}));

export const FirstSectionInner = styled(Box)(({ theme, styles }) => ({
  maxWidth: "600px",
  marginBottom: "150px",
}));

export const MainTitle = styled(Typography)(({ theme, styles }) => ({
  fontSize: "50px",
  fontFamily: "Gilroy, sans-serif",
  marginTop: "150px",
  color: "var(--main-color)",
  ...styles,
}));

export const MainSubtitle = styled(Typography)(({ theme, styles }) => ({
  fontFamily: "Gilroy, sans-serif",
  margin: "30px 0",
  ...styles,
}));

//
export const SecondSection = styled(Box)(({ theme, styles }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));
export const SecondSectionInner = styled(Box)(({ theme, styles }) => ({
  textAlign: "center",
  marginTop: "30px",
}));

export const Title = styled(Typography)(({ theme, styles }) => ({
  fontSize: "35px",
  fontFamily: "Gilroy, sans-serif",
  color: "var(--main-color)",
}));
export const Subtitle = styled(Typography)(({ theme, styles }) => ({
  fontFamily: "Gilroy, sans-serif",
  fontSize: "14px",
}));

export const BoxForCards = styled(Box)(({ theme, styles }) => ({
  marginTop: "40px",
  display: "flex",
  justifyContent: "space-evenly",
  maxWidth: "1300px",
  flexDirection: "column",
}));
export const MyCard = styled(Box)(({ theme, img, styles }) => ({
  maxWidth: "90%",
  minHeight: "300px",
  padding: "30px",
  // backgroundColor: "#00e9ff14",
  borderRadius: "35px",
  margin: "30px",
  position: "relative",
  transition: "transform 0.3s ease",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.015)",
  },
  background:
    " linear-gradient(218deg, rgb(42 180 166 / 26%) 27%, rgb(0 0 0 / 0%) 100%)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  ...styles,
}));

export const CardIcon = styled("img")(({ theme, styles }) => ({
  maxWidth: "70px",
  marginBottom: "20px",
}));
export const CardTitle = styled(Typography)(({ theme, styles }) => ({
  fontSize: "30px",
  fontFamily: "Gilroy, sans-serif",
  color: "var(--main-color)",
  ...styles,
}));
export const CardSubtitle = styled(Typography)(({ theme, styles }) => ({
  fontFamily: "Gilroy, sans-serif",
  marginTop: "10px",
  fontSize: "17px",
  width: "60%",
  ...styles,
  "@media all and (max-width: 700px)": {
    width: "100%",
    zIndex: "2",
  },
}));

//

export const ThirdSection = styled(Box)(({ theme, styles }) => ({
  marginTop: "100px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const ProgrammLanguage = styled(Box)(({ theme, styles }) => ({
  margin: "50px",
  width: "100%",
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  flexWrap: "wrap",
}));

export const ProgrammIcon = styled("img")(({ theme, styles }) => ({
  margin: "25px",
  "@media all and (max-width: 700px)": {
    width: "90px",
    margin: "15px",
  },
}));

//

export const FourthSection = styled(Box)(({ theme, styles }) => ({
  marginTop: "100px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
}));

export const FourthSectionInner = styled(Box)(({ theme, styles }) => ({
  marginTop: "50px",
  display: "flex",
  justifyContent: "center",
  maxWidth: "1300px",
  flexWrap: "wrap",
}));
export const StepCard = styled(Box)(({ theme, styles }) => ({
  margin: "30px",
  textAlign: "start",
  maxWidth: "700px",
  "@media all and (max-width: 700px)": {
    textAlign: "center",
  },
}));
export const StepIcon = styled("img")(({ theme, styles }) => ({
  width: "70px",
}));
export const Line = styled("hr")(({ theme, styles }) => ({
  marginTop: "20px",
  marginBottom: "10px",
}));
export const StepArrow = styled("img")(({ theme, styles }) => ({
  maxWidth: "200px",
  maxHeight: "200px",
  ...styles,
  position: "relative",
  "@media all and (max-width: 700px)": {
    display: "none",
  },
}));

export const StepArrowMobile = styled("img")(({ theme, styles }) => ({
  maxWidth: "200px",
  maxHeight: "200px",
  position: "absolute",
  ...styles,
  opacity: "0.7",
  display: "none",
  "@media all and (max-width: 700px)": {
    display: "inline-block",
  },
}));

//

export const FifthSection = styled(Box)(({ theme, styles }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  marginTop: "100px",

  "@media all and (max-width: 1000px)": {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
}));
export const FifthInnerOne = styled(Box)(({ theme, styles }) => ({
  maxWidth: "350px",
}));
export const FifthTitleOne = styled(Typography)(({ theme, styles }) => ({
  fontSize: "35px",
  fontFamily: "Gilroy, sans-serif",
  color: "var(--main-color)",
  lineHeight: "46px",
  marginBottom: "30px",
}));
export const FifthOneBox = styled(Box)(({ theme, styles }) => ({
  "@media all and (max-width: 1000px)": {
    marginBottom: "50px",
  },
}));
export const FifthOneImg = styled("img")(({ theme, styles }) => ({
  height: "350px",
  borderRadius: "50px",
  maxWidth: "350px",
  objectFit: "cover",
  boxShadow:
    "-11.09000015258789px 18.1299991607666px 36.648292541503906px 0px #99efd045",
}));

export const FifthInnerTwo = styled(Box)(({ theme, styles }) => ({
  display: "flex",
  justifyContent: "center",
  maxWidth: "820px",
  "@media all and (max-width: 1000px)": {
    flexWrap: "wrap",
    textAlign: "center",
  },
}));
export const FifthInnerTwoBox = styled(Box)(({ theme, styles }) => ({}));
export const FifthTwoCard = styled(Box)(({ theme, styles }) => ({
  margin: "0 30px 30px 30px",
  maxWidth: "350px",
}));
export const FifthTwoTitle = styled(Typography)(({ theme, styles }) => ({
  fontSize: "25px",
  fontFamily: "Gilroy, sans-serif",
  color: "var(--main-color)",
}));
export const FifthTwoSubtitle = styled(Typography)(({ theme, styles }) => ({
  fontFamily: "Gilroy, sans-serif",
  fontSize: "15px",
  marginTop: "20px",
}));

export const TechnologyBox = styled(Box)(({ theme, styles }) => ({
  display: "flex",
  marginTop: "20px",
}));

export const TechnologyBoxInner = styled(Box)(({ theme, styles }) => ({
  width: "40px",
  height: "40px",
  border: "1px solid #99efd054",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#0d1315",
  marginRight: "15px",
}));

export const IconTechnology = styled("img")(({ theme, styles }) => ({
  width: "27px",
}));

export const CardBackgroundBox = styled(Box)(({ theme, styles }) => ({
  position: "absolute",
  ...styles,
  "@media all and (max-width: 700px)": {
    opacity: "0.2",
  },
}));

export const CardBackground = styled("img")(({ theme, styles }) => ({
  width: "100%",
  borderRadius: "35px",
}));
