import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import icon1 from "svg/icon1.svg";
import icon2 from "svg/icon2.svg";
import icon3 from "svg/icon3.svg";
import icon4 from "svg/icon4.svg";
import icon5 from "svg/icon5.svg";
import star1 from "svg/star1.svg";
import star2 from "svg/star2.svg";
import star3 from "svg/star3.svg";
import star4 from "svg/star4.svg";
import styled from "@emotion/styled";

export default function SecondSection() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        marginTop: "100px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography
          sx={{
            fontFamily: 'Gilroy, "sans-serif"',
            color: "#99EFD0",
            fontSize: "42px",
          }}
          variant="h3"
        >
          {t("secondSection.mainTitle")}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Gilroy, "sans-serif"',
            color: "white",
            fontSize: "16px",
            marginTop: "13px",
          }}
        >
          {t("secondSection.mainSubtitle")}
        </Typography>
      </Box>

      <SecondSectionBox>
        <MainBox>
          <Box>
            <Icon data-aos="zoom-in" src={icon1} alt="arrow" />
          </Box>
          <MainBoxInner>
            <Title>{t("secondSection.advancedSolutions.title")}</Title>
            <SubTitle>{t("secondSection.advancedSolutions.subtitle")}</SubTitle>
          </MainBoxInner>
        </MainBox>
        <MainBox>
          <Box>
            <Icon data-aos="zoom-in" src={icon2} alt="satellite" />
          </Box>
          <MainBoxInner>
            <Title>{t("secondSection.stunningDesigns.title")}</Title>
            <SubTitle>{t("secondSection.stunningDesigns.subtitle")}</SubTitle>
          </MainBoxInner>
        </MainBox>
        <MainBox>
          <Box>
            <Icon data-aos="zoom-in" src={icon3} alt="radio waves" />
          </Box>
          <MainBoxInner>
            <Title>{t("secondSection.attentionToDetail.title")}</Title>
            <SubTitle>{t("secondSection.attentionToDetail.subtitle")}</SubTitle>
          </MainBoxInner>
        </MainBox>
        <MainBox sx={{ marginTop: "43px" }}>
          <Box>
            <Icon data-aos="zoom-in" src={icon4} alt="zipper" />
          </Box>
          <MainBoxInner>
            <Title>{t("secondSection.customizableOptions.title")}</Title>
            <SubTitle>{t("secondSection.customizableOptions.subtitle")}</SubTitle>
          </MainBoxInner>
        </MainBox>
        <MainBox sx={{ marginTop: "43px" }}>
          <Box>
            <Icon data-aos="zoom-in" src={icon2} alt="satellite" />
          </Box>
          <MainBoxInner>
            <Title>{t("secondSection.customerSatisfaction.title")}</Title>
            <SubTitle>{t("secondSection.customerSatisfaction.subtitle")}</SubTitle>
          </MainBoxInner>
        </MainBox>
        <MainBox sx={{ marginTop: "43px" }}>
          <Box>
            <Icon data-aos="zoom-in" src={icon5} alt="light bulb" />
          </Box>
          <MainBoxInner>
            <Title>{t("secondSection.boostedOnlinePresence.title")}</Title>
            <SubTitle>{t("secondSection.boostedOnlinePresence.subtitle")}</SubTitle>
          </MainBoxInner>
        </MainBox>
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ left: "-140px", bottom: "100px" }}
          src={star1}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ left: "-23px", top: "-187px" }}
          src={star4}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ left: "-88px", bottom: "252px" }}
          src={star2}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ left: "29px", top: "-200px" }}
          src={star3}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ left: "-66px", top: "0px" }}
          src={star4}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ left: "-80px", bottom: "420px" }}
          src={star1}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ left: "-116px", top: "-166px" }}
          src={star3}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ left: "-200px", top: "-166px" }}
          src={star2}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ left: "-201px", bottom: "49px" }}
          src={star3}
          alt="star"
        />

        {/*  */}
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ right: "-26px", bottom: "252px" }}
          src={star2}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ right: "29px", top: "-200px" }}
          src={star3}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ right: "-80px", bottom: "90px" }}
          src={star4}
          alt="star"
        />

        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ right: "-116px", top: "-166px" }}
          src={star3}
          alt="star"
        />
        <PositionedStar
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="zoom-in"
          position={{ right: "-200px", top: "-166px" }}
          src={star2}
          alt="star"
        />
      </SecondSectionBox>
    </Box>
  );
}
export const PositionedStar = styled("img")(({ theme, position }) => ({
  position: "absolute",
  opacity: "0.2",
  ...position,
}));

const MainBox = styled(Box)(({ theme }) => ({
  display: "flex",
  maxWidth: "299px",
  marginTop: "43px",
  "@media all and (max-width: 650px)": {
    flexDirection: "column",
    alignItems: "center",
  },
}));
const MainBoxInner = styled(Box)(({ theme }) => ({
  marginLeft: "28px",
  "@media all and (max-width: 650px)": {
    textAlign: "center",
    marginLeft: "0",
  },
}));
const Title = styled(Box)(({ theme }) => ({
  color: "#99EFD0",
  fontSize: "32px",
  fontFamily: "Gilroy, sans-serif",
}));
const SubTitle = styled(Box)(({ theme }) => ({
  marginTop: "10px",
  fontSize: "17px",
  color: "white",
  fontFamily: "Gilroy, sans-serif",
}));
export const SecondSectionBox = styled(Box)(({ theme }) => ({
  marginTop: "20px",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  maxWidth: "1050px",
  position: "relative",
  "@media all and (max-width: 950px)": {
    justifyContent: "space-evenly",
  },
  "@media all and (max-width: 650px)": {
    justifyContent: "center",
  },
}));
const Icon = styled("img")(({ theme }) => ({
  "@media all and (max-width: 650px)": {
    width: "50px",
  },
}));
