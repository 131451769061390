import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Frame from "../images/Frame.png";
import "./Project.css";

export default function Project() {
  const { t } = useTranslation();

  return (
    <div className="project-block">
      <Typography fontWeight="bold" fontSize="36px" className="project">
        {t("project.title")}
      </Typography>
      <br />
      <img src={Frame} alt="Frame" className="frame-img" />
    </div>
  );
}
