import React from "react";
import {
  CardSubtitle,
  CardTitle,
  FourthSection,
  FourthSectionInner,
  Line,
  StepArrow,
  StepArrowMobile,
  StepCard,
  StepIcon,
  Title,
} from "./styled";

import one from "svg/one.svg";
import two from "svg/two.svg";
import three from "svg/three.svg";

import arrowOne from "images/arrowOne.png";
import arrowTwo from "images/arrowTwo.png";
import arrow3 from "images/arrow3.png";
import arrow4 from "images/arrow4.png";

import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function FourSection() {
  const { t } = useTranslation();

  return (
    <FourthSection>
      <Title>{t("sections.services.developmentSteps.title")}</Title>
      <FourthSectionInner>
        <StepBoxInner>
          <StepCard>
            <StepIcon src={one} alt="icon number one" />
            <Line />
            <StepTitle styles={{ fontSize: "25px" }}>
              {t("sections.services.developmentSteps.step1.title")}
            </StepTitle>
            <StepSubtitle>
              {t("sections.services.developmentSteps.step1.subtitle")}
            </StepSubtitle>
          </StepCard>
          <StepArrow styles={{ top: "110px" }} src={arrowOne} />
          <StepArrowMobile
            styles={{ top: "190px", left: "-80px" }}
            src={arrow3}
          />
        </StepBoxInner>
        <StepBoxInner>
          <StepArrow styles={{ top: "150px" }} src={arrowTwo} />
          <StepCard>
            <StepIcon src={two} alt="icon number one" />
            <Line />
            <StepTitle styles={{ fontSize: "25px" }}>
              {t("sections.services.developmentSteps.step2.title")}
            </StepTitle>
            <StepSubtitle>
              {t("sections.services.developmentSteps.step2.subtitle")}
            </StepSubtitle>
          </StepCard>
          <StepArrowMobile
            styles={{ top: "190px", right: "-80px" }}
            src={arrow4}
          />
        </StepBoxInner>
        <StepBoxInner>
          <StepCard>
            <StepIcon src={three} alt="icon number one" />
            <Line />
            <StepTitle styles={{ fontSize: "25px" }}>
              {t("sections.services.developmentSteps.step3.title")}
            </StepTitle>
            <StepSubtitle>
              {t("sections.services.developmentSteps.step3.subtitle")}
            </StepSubtitle>
          </StepCard>
        </StepBoxInner>
      </FourthSectionInner>
    </FourthSection>
  );
}

export const StepTitle = styled(CardTitle)(({ theme, styles }) => ({
  fontSize: "20px",
}));
export const StepSubtitle = styled(CardSubtitle)(({ theme, styles }) => ({
  width: "100%",
}));

export const StepBoxInner = styled(Box)(({ theme, styles }) => ({
  display: "flex",
  position: "relative",
}));
